<template>
  <lv-tooltip
    :key="`tooltip-secondary-version2`"
    v-model:show-tooltip="tooltipOpened"
    :trigger="categoryList?.length ? 'hover' : 'none'"
    styling="secondary-version2"
    :options="{
      placement: 'bottom-start',
      delayOnMouseOut: 1000
    }"
  >
    <template #reference>
      <slot />
    </template>
    <template #tooltip>
      <ul
        :id="`aw_breadcrumb_category_tooltip_${categoryId}`"
        :aria-describedby="`aw_breadcrumb_category_tooltip_description_${categoryId}`"
        :class="[$style.tooltipContent, 'ulReset']"
      >
        <li
          v-for="cat in categoryList"
          :key="cat.token"
          :aria-current="parseInt(getCategoryFromTree.id) === parseInt(cat.id) && 'page'"
          :class="[
            $style.tooltipContentItem,
            {[$style.tooltipContentItemCurrent]: parseInt(getCategoryFromTree.id) === parseInt(cat.id)}
          ]"
        >
          <lv-link
            :to="cat.to"
            :class="$style.tooltipContentItemLink"
            tabindex="0"
          >
            {{ cat.token }}
          </lv-link>
        </li>
      </ul>
    </template>
  </lv-tooltip>
</template>

<script>
  import { useCategoryStore } from '~~/common/stores/category';
  import urlMixin from '~~/common/mixins/urlMixin';
  import { LvLink, LvTooltip } from '~~/common/components/loginet-vue-shop/index.mjs';
  import { mapState } from 'pinia';

  export default {
    name: 'AwBreadcrumbCategoryTooltip',
    components: {
      LvLink,
      LvTooltip,
    },
    mixins: [urlMixin],
    props: {
      categoryId: {
        type: Number,
        default: null,
      },
    },
    data () {
      return {
        tooltipOpened: false,
      };
    },
    computed: {
      category () {
        const categoryStore = useCategoryStore();
        return categoryStore.getCategoryFromTreeById({ treeId: parseInt(this.categoryId) });
      },
      ...mapState(useCategoryStore, {
        getCategoryFromTree: state => state.getCategoryFromTree,
      }),
      categoryList () {
        return this.category?.children?.map(category => ({
          token: category.name,
          to: this.getCategoryUrl(category),
          id: category.id,
        }));
      },
    },
    watch: {
      tooltipOpened (val) {
        if (val) {
          this.$awEvents.triggerEvent('breadcrumb-category-tooltip-opened', { categoryId: this.categoryId });
          this.$awEvents.addEvent('breadcrumb-category-tooltip-opened', this.closeTooltipByEvent);
        } else {
          this.closeTooltip();
        }
      },
    },
    methods: {
      closeTooltipByEvent (event) {
        if (event.categoryId === this.categoryId) {
          return;
        }
        this.closeTooltip();
      },
      closeTooltip () {
        this.tooltipOpened = false;
        this.$awEvents.removeEvent('breadcrumb-category-tooltip-opened', this.closeTooltipByEvent);
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.wrapper {
  position: relative;
}

.tooltip {
  width: 200px;
  padding: 8px 8px 0;
  border-radius: 12px;

  &Content {
    display: flex;
    overflow-x: hidden;
    overflow-y: auto;
    align-items: flex-start;
    flex-direction: column;
    max-height: 5.5*36px;
    text-align: left;
    scrollbar-width: none;

    &Item {
      font-size: 14px;
      line-height: 20px;
      width: 100%;
      color: $color-text-primary;
      border-radius: 8px;

      &Current {
        color: $color-marigold-text;
        background: $color-background-pending;
      }

      &:hover:not(&Current) {
        background-color: $color-background-3;
      }

      &Link {
        padding: 8px;
        text-decoration: none !important;
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
</style>
