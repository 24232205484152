<template>
  <nav :class="$style.breadcrumb" :aria-label="$awt('aw.common.components.breadcrumb.label')" tabindex="0">
    <template v-for="(breadcrumb, index) in breadcrumbs" :key="`link-${index}`">
      <component
        :is="hasTooltip ? 'aw-breadcrumb-category-tooltip' : 'span'"
        v-if="isActive(breadcrumb, index)"
        :category-id="breadcrumb.categoryId"
      >
        <lv-link
          :to="breadcrumb.to"
          :class="[$style.breadcrumbItem]"
        >
          {{ breadcrumb.token }}
        </lv-link>
      </component>
      <div v-else>
        <span
          :class="[$style.breadcrumbItem, $style.breadcrumbItemLastChild, {[$style.breadcrumbItemDisabled]: breadcrumb.disabled}]"
          :aria-current="index === breadcrumbs.length -1 ? 'page' : null"
        >
          {{ breadcrumb.token }}
        </span>
      </div>
      <span v-if="index !== breadcrumbs.length - 1" :key="`separator-${index}`" :class="[$style.breadcrumbSeparator]">
        ›
      </span>
    </template>
  </nav>
</template>

<script>
  import { mapState } from 'pinia';
  import { LvLink } from '~~/common/components/loginet-vue-shop/index.mjs';
  import { useBreadcrumbStore } from '~~/common/stores/breadcrumb';
  import AwBreadcrumbCategoryTooltip from './AwBreadcrumbCategoryTooltip.vue';

  export default {
    name: 'AwBreadcrumbVersion2',
    components: {
      LvLink,
      AwBreadcrumbCategoryTooltip,
    },
    props: {
      externalBreadcrumbs: {
        type: Array,
        default: null,
      },
      hasTooltip: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...mapState(useBreadcrumbStore, {
        storeBreadCrumbs: 'breadcrumbs',
      }),
      breadcrumbs () {
        return this.externalBreadcrumbs || this.storeBreadCrumbs;
      },
    },
    jsonld () {
      return {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: this.breadcrumbs.map((breadcrumb, i) => ({
          '@type': 'ListItem',
          position: i + 1,
          name: breadcrumb.token,
          item: this.$awRuntimeConfig.auchanApiBrowserHost + breadcrumb.to,
        })),
      };
    },
    methods: {
      isActive (breadcrumb, index) {
        return !breadcrumb.disabled && index !== this.breadcrumbs.length - 1;
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
$breadcrumb-color-primary: $color-text-primary;
$breadcrumb-color-disabled: $color-text-secondary;

.breadcrumb {
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: 8px;
  @include sm(max) {
    overflow: visible;
    white-space: normal;
  }

  &Item {
    font-family: $secondary-font;
    font-size: 12px;
    font-weight: $font-weight-medium-v2;
    font-style: normal;
    line-height: 16px;
    word-break: break-word;
    hyphens: auto;
    color: $breadcrumb-color-primary;

    @include desktop-small(min) {
      font-size: 14px;
      line-height: 20px;
    }

    &LastChild {
      font-weight: $font-weight-regular-v2;
      user-select: none;
      color: $breadcrumb-color-disabled;
      @include tablet(min) {
        font-weight: $font-weight-normal-v2;
      }

      &:hover {
        cursor: default;
      }
    }

    &Disabled {
      padding-top: 1px;
      user-select: none;
      color: $breadcrumb-color-disabled;

      &:hover {
        cursor: default;
        color: $breadcrumb-color-disabled;
      }
    }
  }

  &Separator {
    font-family: $secondary-font;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    line-height: 20px;
    text-align: center;
    color: $color-text-tertiary;
  }
}
</style>
